
import ReactAplayer from "react-aplayer";

import "./Mp3Player.css";
import { useEffect } from "react";


// 可以把选中的歌 选择播放 选中后自动添加到播放列表~~~

// add a new declaration (.d.ts) file containing `declare module 'react-aplayer
// Hugo是由Go语言实现的静态网站生成器。简单、易用、高效、易扩展、快速部署
export function Mp3Player({ visible, setVisible, ap, setAp,setMusics,musics,onApInit }: any) {
  // const [ap, setAp] = useState<any>(null);


  const onPlay = () => {
    // console.log("on play~~~~");
  };

  const onPause = () => {
    // console.log("on pause~~~~");
  };



  // example of access aplayer instance
  const onInit = (ap: any) => {
    // setAp(ap);
    onApInit(ap)
  };

  const props = {
    // fixed:true,
    // mini:true,
    listMaxHeight: "0",
    listFolded: true,
    theme: "#F57F17",
    lrcType: 1,  // 1js string 3.lrc文件
    audio: musics,
  };

  useEffect(() => {
    // console.log("mp3~~~~~");
  }, []);

  return (
 <ReactAplayer
  {...props}
  onPlay={onPlay}
  onPause={onPause}
  onInit={onInit}
  // loop="none"
  // order="random"
  // autoplay="true"
  
/>
  );
}
