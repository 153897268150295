import { useEffect, useRef, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import {} from "antd-mobile-icons";
import {
  UnorderedListOutline,
  LeftOutline,
  RightOutline,
  ArrowsAltOutline,
  ShrinkOutline,
  DeleteOutline,
  LoopOutline,
} from "antd-mobile-icons";
import { Mp3Player } from "../components/Mp3Player";

import "./layout_index.css";
import {
  Button,
  List,
  Space,
  Tabs,
  Tag,
  Toast,
  Image,
  Footer,
  CenterPopup,
  Popup,
  AutoCenter,
} from "antd-mobile";
import pause from "../assets/pause.png";
import play from "../assets/play.png";
import playing from "../assets/playing.gif";
import { deleteMusic, addMusic2Playlist, httpPostPlaylist } from "../apis/home";
import GoCaptcha from "go-captcha-react";
import Cookies from "js-cookie";

interface CaptchaData {
  thumb_x: number;
  thumb_y: number;
  thumb_w: number;
  thumb_h: number;
  master_bg: string;
  thumb_bg: string;
  captcha_key: string;
}
// 页面是否首次加载 解决频繁获取歌单接
let isPageFirstLoad = true;

// 当前Play下标
let currentPlayIndex = 0;

// 上一首 和 下一首的默认启步index
let currentStart: number = 0;

// 全局选中播放列表
let currentSelectList: Array<number> = [];
// 随机播放的倒计时
let currentSelectListCopy: Array<number> = [];

// 全局播放器对像
let myApObject: any = null;

// 选中模式下的播放次数
let currentPlayCount = 1;

// 播放模式 0 循环 1随机
let currentPlayMode: number = 0;

export default function Layout() {
  // 滑动验证码
  const [sliderData, setSliderData] = useState<CaptchaData>(); // 返回滑动验证码的配置信息
  const [mp3Data, setMp3Data] = useState<any>({}); // mp3的相关信息
  const [popvisible, setPopVisible] = useState(false);
  const [popvisible2, setPopVisible2] = useState(false);
  const [userName, setUserName] = useState<string>("");
  const [visible, setVisible] = useState<boolean>(false);
  const [ap, setAp] = useState<any>(null);
  // 当前播放的item
  const [currentPlay, setCurrentPlay] = useState<number>(0);
  // 标准播放
  const [isPaused, setIsPaused] = useState<boolean>(false);
  const [msg,setMsg] = useState<string>("");
  // 
  const navigate = useNavigate();
  const myRef = useRef(null);

  // 全局音乐数据     sessionStorage.getItem("playlist");
  const [musics, setMusics] = useState<any>([]);

  // 展开歌单
  const [isCollapse, setIsCollapse] = useState<boolean>(true);
  // 播放次数
  const [tagIndex, setTagIndex] = useState<number>(1);
  // 播放模式 0 循环 1随机
  const [playMode, setPlayMode] = useState<number>(0);

  // 当前滑动状态下激活的item下标
  const [activeItem, setAcviteItem] = useState<number>(-1);

  function playSound(url: string, index: number, e: any) {
    // 能添加进播放列表中的歌曲不需要做权限校验
    // 点击item播放
    if (e.target.nodeName === "DIV") {
      // ap.list.show();
      ap?.list.switch(index);
      ap?.play();
      currentPlayIndex = index;
      setCurrentPlay(currentPlayIndex);
      setIsPaused(true);

      // 更新上一首和下一首的下标
      currentStart = currentPlayIndex;
    }
  }

  function changePlayMode() {
    // 重置播放列表
    currentSelectListCopy = [...currentSelectList];
    if (playMode === 0) {
      // 随机模式
      setPlayMode(1);
      currentPlayMode = 1;
    } else {
      // 循环模式
      setPlayMode(0);
      currentPlayMode = 0;
    }
  }

  function onPrevSound() {
    if (currentSelectList[0] === currentPlayIndex) {
      currentPlayIndex = currentSelectList[currentSelectList.length - 1];
      currentStart = currentSelectList.length - 1;
    } else {
      currentStart -= 1;
      currentPlayIndex = currentSelectList[currentStart];
    }
    setCurrentPlay(currentPlayIndex);
    myApObject.list.switch(currentPlayIndex);
  }

  function onNextSound() {
    if (currentSelectList[currentSelectList.length - 1] === currentPlayIndex) {
      currentPlayIndex = currentSelectList[0];
      currentStart = 0;
    } else {
      currentStart += 1;
      currentPlayIndex = currentSelectList[currentStart];
    }
    setCurrentPlay(currentPlayIndex);
    myApObject.list.switch(currentPlayIndex);
  }

  function checkUniqueMusic(item: any) {
    let isRepeat = false;
    myApObject.list.audios.map((d: any, idx: number) => {
      if (d.id === item.id) {
        // 重置播放下标
        currentPlayIndex = idx;
        setCurrentPlay(currentPlayIndex);
        myApObject.list.switch(idx);
        myApObject.play();
        setIsPaused(true);
        isRepeat = true;
      }
    });
    return isRepeat;
  }

  // 添加歌曲到歌单
  async function addPlayList(e: any, item: any, index: any) {
    addPlayListHandler(item);
    setMp3Data(item);
  }

  async function addPlayListHandler(item: any) {
    if (checkUniqueMusic(item)) {
      Toast.show({
        content: "歌曲已存在歌单中",
        duration: 1000,
      });
      scrollTopBar(item);
      return;
    }
    const { id, name, album_cover_image, singer, lrc_text } = item;
    //  通过mp3_id来判断 调用接口  如果有权限 返回mp3_url 否则return
    //  用户登录后 根据状态来获取用户的数据 放在sessionStorage或全局变量中
    const imageUrl = album_cover_image.split("https://images.cto163.com")[1];

    const res: any = await addMusic2Playlist({
      id,
      name,
      album_cover_image: imageUrl,
      singer,
      lrc_text,
      device_id: window.visitorId,
      captcha_key: mp3Data?.captchakey, // 滑动验证码会话id
      x: mp3Data?.x, // 滑动后的x的位置
      y: mp3Data?.y, // 滑动后的y的位置
    });

    // 接口调用完成后 删除过期的滑块x,y位置信息 和验证码key
    delete mp3Data?.x;
    delete mp3Data?.y;
    delete mp3Data?.captchakey;
    // 验证码
    if (res?.code == undefined) {
      setSliderData({ ...res });
      setPopVisible(true);
      return;
    }
    // 超出游客日访问量 或 超出游客免费时间 请登录或注册会员
    if (res?.code == 5004 || res?.code == 5003) {
      setMsg(res?.error);
      setPopVisible2(true);
      return;
    }



    // 异常的业务信息
    if (res?.code !== 200) {
      Toast.show({
        maskClickable: false,
        content: res?.error,
        duration: 3000,
      });
      return;
    }

    let obj = {
      id: item.id,
      name: item.name,
      artist: item.singer,
      album: item.album,
      url: res.data,
      cover: item.album_cover_image,
      lrc: `${item.lyrics}`,
      theme: "#ebd0c2",
      // selected:true,
      maxPlayCount: currentPlayCount,
    };

    // 操作播放器的真实数据
    if (myApObject.list.audios.length === 0) {
      setCurrentPlay(0);
      currentPlayIndex = 0;
    }

    // 添加播放器
    myApObject.list.add(obj);
    // 更新播放列表显示
    setMusics([...myApObject.list.audios]);
    // 更新选中列表
    currentSelectList.push(myApObject.list.audios.length - 1);
    // 立刻播放加进播放列表的歌
    currentPlayIndex = myApObject.list.audios.length - 1;
    setCurrentPlay(currentPlayIndex);
    myApObject.list.switch(currentPlayIndex);
    myApObject.play();
    setIsPaused(true);

    // 更新上一首&下一首 下标
    currentStart = currentPlayIndex;

    Toast.show({
      maskClickable: false,
      content: "添加成功",
      duration: 1000,
      afterClose: () => {
        setPopVisible(false);
      },
    });

    // 新添加的歌曲渲染结束后 再滚动
    let timer = setTimeout(() => {
      clearTimeout(timer);
      scrollTopBar(item);
    }, 300);

    // 保存
    // localStorage.setItem("playlist", JSON.stringify(myApObject.list.audios));
  }

  // 滚动到播放的歌曲位置
  function scrollTopBar(data: any) {
    // 获取当前item在播放列表的index
    const index = myApObject.list.audios.findIndex((item: any) => {
      return data.id === item.id;
    });
    const obj = myRef.current as unknown as HTMLDivElement;
    obj.scrollTop = 52 * index;
  }

  async function deleteItem(item: any, index: number) {
    if (!userName) {
      Toast.show({
        content: "请您登录后再删除...",
        duration: 1000,
      });
      return;
    }

    const res: any = await deleteMusic({
      device_id: window.visitorId,
      id: item.id,
    });

    if (res.code !== 200) {
      Toast.show({
        content: res.msg,
        duration: 1000,
      });
      return;
    }

    const restData = myApObject.list.audios.filter(
      (obj: any) => item.id !== obj.id
    );
    //  更新数据
    setMusics([...restData]);

    // 删除aplayer
    myApObject.list.remove(index);

    // 更新下标~~
    currentSelectList = currentSelectList.filter(
      (idx: number) => index !== idx
    );

    // 每次删除后 重置一下下标列表
    let resetList: any = [];
    myApObject.list.audios.map((item: any, index: any) => {
      resetList.push(index);
    });
    currentSelectList = [...resetList];

    // 重置gif
    if (index < currentPlayIndex) {
      currentPlayIndex = currentPlayIndex - 1;
      setCurrentPlay(currentPlayIndex);
    }

    if (index > currentPlayIndex) {
    }

    // 当删除的歌正在播放
    if (currentPlayIndex === index) {
      // 如果是最后一首歌 其它情况正常
      let last = myApObject.list.audios.length;
      if (index === last) {
        myApObject.list.switch(last - 1);
        setCurrentPlay(last - 1);
        currentPlayIndex = last - 1;
      }
    }

    // 如果播放列表被清空
    if (currentSelectList.length === 0) {
      myApObject.pause();
      setIsPaused(false);
    }

    // 更新上一首&一下首下标
    currentStart = currentPlayIndex;

    // 保存
    // localStorage.setItem("playlist", JSON.stringify(myApObject.list.audios));
  }

  function onItemMouseOver(e: any, item: any, index: number) {
    e.stopPropagation();
    setAcviteItem(index);
  }

  function getRandomElement(arr: any) {
    const randomIndex = Math.floor(Math.random() * arr.length);
    const element = arr[randomIndex];
    arr.splice(randomIndex, 1); // 从数组中移除该元素
    return element;
  }

  // 播放
  function playSelected() {
    if (currentSelectList.length === 0) {
      pickAllList();
    }

    ap.toggle();

    setIsPaused(true);
  }

  //  暂停选中
  function stopSelected() {
    ap?.toggle();

    setIsPaused(false);
  }

  function onApInit(ap: any) {
    setAp(ap);
    myApObject = ap;
    // 附加事件监听器
    ap?.on("ended", handleEnded);
  }

  function handleEnded() {
    // 获取aplay播放列表
    let musics = myApObject.list.audios;

    // 如果是随机播放
    if (currentPlayMode === 1) {
      const all = musics.map((item: any, index: number) => {
        return index;
      });

      // 如果随机播放到最后一首歌 再重新赋值播放
      if (currentSelectListCopy.length === 0) {
        currentSelectListCopy = [...currentSelectList];
        // 重置播放次数
        const data = musics.map((item: any) => {
          item.maxPlayCount = currentPlayCount;
          return item;
        });
        setMusics(data);
        // 重置播放下标
        currentPlayIndex = currentSelectList[0];
        setCurrentPlay(currentPlayIndex);
        myApObject.list.switch(currentPlayIndex);
        // 更新
        currentStart = currentPlayIndex;

        return;
      }

      // 有播放次数
      let idx: number = all[currentPlayIndex];
      if (musics[idx]?.maxPlayCount > 1) {
        musics[idx].maxPlayCount = musics[idx].maxPlayCount - 1;
      } else {
        // 没有播放次数 就随机抽取
        currentPlayIndex = getRandomElement(currentSelectListCopy);
      }
      setCurrentPlay(all[currentPlayIndex]);
      myApObject.list.switch(all[currentPlayIndex]);
      // update
      currentStart = currentPlayIndex;

      return;
    }

    // 如果是选中播放
    if (currentSelectList.length > 0) {
      // 如果播放到最后一首歌
      if (currentSelectList.length - 1 === currentPlayIndex) {
        // 有播放次数
        let idx: number = currentSelectList[currentPlayIndex];
        if (musics[idx].maxPlayCount > 1) {
          musics[idx].maxPlayCount = musics[idx].maxPlayCount - 1;
        } else {
          // 没有播放次数
          currentPlayIndex = 0;
          // 重置播放次数
          const data = musics.map((item: any) => {
            item.maxPlayCount = currentPlayCount;
            return item;
          });

          setMusics(data);
        }
      } else {
        // 获取播放次数
        let idx: number = currentSelectList[currentPlayIndex];
        if (musics[idx].maxPlayCount > 1) {
          musics[idx].maxPlayCount = musics[idx].maxPlayCount - 1;
        } else {
          currentPlayIndex += 1;
        }
      }

      setCurrentPlay(currentSelectList[currentPlayIndex]);
      myApObject.list.switch(currentSelectList[currentPlayIndex]);
      // update
      currentStart = currentPlayIndex;

      return;
    }
  }

  function addPlayCount(count: number) {
    setTagIndex(count);
    currentPlayCount = count;
    // 设置单个歌曲的播放次数
    const data = musics.map((item: any) => {
      item.maxPlayCount = count;
      return item;
    });

    setMusics(data);
  }

  // 可选：移除事件监听器以防止重复附加
  function togglePanel() {
    const panel = document.getElementById("panel");
    panel?.classList.toggle("show");
    setIsCollapse(!isCollapse);

    if (isCollapse) {
      getPlaylist();
    }
  }

  // 全部选中
  function pickAllList() {
    ap?.pause();

    setIsPaused(false);

    let list: Array<number> = [];
    const d = musics.map((item: any, index: any) => {
      list.push(index);
      // item.selected = true;
      return item;
    });

    currentSelectList = [...list];
    currentSelectListCopy = [...list];
    setMusics(d);

    // 重置
    ap?.list.switch(currentSelectList[0]);
    setCurrentPlay(currentSelectList[0]);
    currentStart = 0;
  }

  // 获取子组件传回的数据
  const addMusic2playlist = (e: any, item: any, index: any) => {
    addPlayList(e, item, index);
 
    if (isCollapse === true) {
      setIsCollapse(false)
      togglePanel()
    }

  };

  // 用户登录&退出的时候 更新歌单
  function userLogout() {
    setUserName("");
    getPlaylist();
  }

  function userLogin() {
    // 用户登录后 此处的方法不渲染 改成点击事件触发
    // getPlaylist();
  }

  async function getPlaylist() {
    const device_id: string = window.visitorId;
    const { data } = await httpPostPlaylist({ device_id });

    if (!data) {
      return;
    }

    const updatedList = data.map((item: any) => ({
      ...item, // 保留其他属性
      artist: item.singer,
      cover: "https://images.cto163.com/" + item.album_cover_image,
      url: item.mp3_url,
    }));

    setMusics([...updatedList]);
    if (updatedList.length > 0) {
      // 去重
      updatedList.forEach((item: any) => {
        const exists = myApObject.list.audios.some(
          (element: any) => element.id === item.id
        );
        if (!exists) {
          myApObject.list.add(item);
        }
      });
    }
  }

  useEffect(() => {
    if (ap) {
      pickAllList();
    }

    // 默认单曲播放3次
    addPlayCount(3);

    // 获取cookie中的username
    const name = Cookies.get("s_username"); // => 'value'
    if (name) {
      setUserName(name);
    }
  }, [ap]);

  useEffect(() => {
    if (isPageFirstLoad) {
      isPageFirstLoad = false;
      getPlaylist();
    }
  }, []);

  return (
    //@ts-ignore
    <div style={styles.container} className="layout_index">
      {/* Outlet context属性允许父组件通过 <Outlet /> 向子路由组件传递数据或方法 */}
      <Outlet context={{ addMusic2playlist, userLogout, userLogin }} />

      <Popup
    
      showCloseButton={true}
        visible={popvisible2}
        onMaskClick={() => {
          setPopVisible2(false);
        }}
        onClose={() => {
          setPopVisible2(false);
        }}
        bodyStyle={{ height: 200 }}
      >
        <AutoCenter style={{ marginTop: 80,fontSize:16 }}>
          {msg}, 请 <Button onClick={()=>{
            setPopVisible2(false)
                   navigate("/user_login");
          }}>登录</Button> 或 <Button onClick={()=>{
            setPopVisible2(false)
            navigate("/new_user");
   }}>注册会员</Button> 
        </AutoCenter>
      </Popup>

      <CenterPopup
        visible={popvisible}
        onMaskClick={() => {
          setPopVisible(false);
        }}
      >
        <GoCaptcha.Slide
          // config配置数据可以自定义 是可选参数
          config={{
            width: 300,
            height: 220,
            verticalPadding: 15,
            horizontalPadding: 15,
            showTheme: true,
            title: `请拖动滑块完成拼图`,
          }}
          // data的数据由go服务端提供
          data={{
            image: sliderData?.master_bg as string, // 背景图 使用base64
            thumb: sliderData?.thumb_bg as string, // 滑块图 使用base64
            thumbX: sliderData?.thumb_x as number, // 滑块x
            thumbY: sliderData?.thumb_y as number, // 滑块y
            thumbWidth: sliderData?.thumb_w as number, // 滑块宽
            thumbHeight: sliderData?.thumb_h as number, // 滑块高
          }}
          events={{
            // click: (x: number, y: number) => {
            //   console.log("click")
            // },
            refresh: async () => {
              console.log("refresh");

              addPlayListHandler(mp3Data);
            },
            close: () => {
              setPopVisible(false);
              console.log("close");
            },
            confirm: async (dots: { x: number; y: number }) => {
              //  在业务数据中加上验证码数据
              mp3Data.x = dots.x;
              mp3Data.y = dots.y;
              // 把接口返回的validate key 给mp3Data 在请求接口时带上
              mp3Data.captchakey = sliderData?.captcha_key;
              setMp3Data(mp3Data);
              addPlayListHandler(mp3Data);
            },
          }}
        />
      </CenterPopup>

      {/* 全局播放器 */}
      <div className="footer" onClick={() => {}}>
        <div className="mp3player">
          <Mp3Player
            ap={ap}
            setAp={setAp}
            visible={visible}
            setVisible={setVisible}
            musics={musics}
            setMusics={setMusics}
            onApInit={onApInit}
          />

          <div className="control_box">
            <Space className="s1">
              <Button color="danger" size="mini">
                选择模式
              </Button>
            </Space>

            <Space
              className="s1"
              justify="center"
              align="center"
              style={{ pointerEvents: musics.length === 0 ? "none" : "all" }}
            >
              {/* musics列表 {musics.length} */}
              <Space
                onClick={() => {
                  onPrevSound();
                }}
                className="prev_button"
                justify="center"
                align="center"
              >
                <LeftOutline className="left_button" fontSize={20} />
              </Space>
              {isPaused ? (
                <>
                  <img
                    className="pause_button"
                    height={32}
                    width={32}
                    src={pause}
                    onClick={(e) => {
                      stopSelected();
                    }}
                  />
                </>
              ) : (
                <img
                  className="play_button"
                  height={32}
                  width={32}
                  src={play}
                  onClick={(e) => {
                    playSelected();
                  }}
                />
              )}
              <Space
                onClick={() => {
                  onNextSound();
                }}
                justify="center"
                align="center"
              >
                <RightOutline className="right_button" fontSize={20} />
              </Space>
            </Space>

            <Space className="s1" justify="end" align="center">
              {/* 播放模式 */}
              模式
              <Button
                size="mini"
                color="danger"
                onClick={() => {
                  changePlayMode();
                }}
              >
                {playMode === 0 ? (
                  <>
                    <LoopOutline /> 循环
                  </>
                ) : (
                  <>
                    <UnorderedListOutline /> 随机
                  </>
                )}
              </Button>
              <span style={{ margin: 8 }}> |</span> 单曲播放
              <Tag
                onClick={() => {
                  addPlayCount(1);
                }}
                className="fontTag"
                color={tagIndex === 1 ? "#ff0000" : "#666"}
              >
                1
              </Tag>
              <Tag
                onClick={() => {
                  addPlayCount(3);
                }}
                className="fontTag"
                color={tagIndex === 3 ? "#ff0000" : "#666"}
              >
                3
              </Tag>
              <Tag
                onClick={() => {
                  addPlayCount(9);
                }}
                className="fontTag"
                color={tagIndex === 9 ? "#ff0000" : "#666"}
              >
                9
              </Tag>
              {/* <Tag
                onClick={() => {
                  addPlayCount(10);
                }}
                className="fontTag"
                color={tagIndex === 10 ? "#ff0000" : "#666"}
              >
                10
              </Tag>
              <Tag
                onClick={() => {
                  addPlayCount(99);
                }}
                className="fontTag"
                color={tagIndex === 99 ? "#ff0000" : "#666"}
              >
                99
              </Tag> */}
              次
            </Space>
          </div>
        </div>

        <div
          onClick={() => {
            togglePanel();
          }}
          className="collapse_button"
        >
          {isCollapse ? (
            <>
              <ArrowsAltOutline fontSize={20} /> 展开歌单
            </>
          ) : (
            <>
              <ShrinkOutline fontSize={20} /> 折叠歌单
            </>
          )}
        </div>
      </div>
      <div className="panel" id="panel">
        <div className="audioContainer">
          <Tabs activeLineMode="fixed">
            {/* <Tabs.Tab title={`播放列表(${currentSelectList.length})`} key="all"> */}
            <Tabs.Tab title={`歌曲列表`} key="all">
              <div className="audioList" ref={myRef}>
                <div style={{ textAlign: "right", margin: "8px 0" }}></div>

                <List>
                  {musics.map((item: any, index: number) => {
                    return (
                      <List.Item
                        className="listItem"
                        prefix={
                          <>
                            <div
                              style={{
                                width: 38,
                                textAlign: "center",
                                fontSize: 13,
                              }}
                            >
                              <img
                                height={12}
                                width={12}
                                src={playing}
                                style={{
                                  visibility:
                                    currentPlay === index && isPaused === true
                                      ? "visible"
                                      : "hidden",
                                }}
                              />

                              {currentPlay === index && isPaused === true ? (
                                <></>
                              ) : (
                                <span style={{ fontWeight: 700 }}>
                                  {index + 1}
                                </span>
                              )}
                            </div>
                          </>
                        }
                        onClick={(e) => {
                          playSound(item.url, index, e);
                        }}
                        key={item.id}
                        arrow={false}
                      >
                        <div
                          className="item_box"
                          style={{ fontSize: 13 }}
                          onMouseOver={(e) => {
                            onItemMouseOver(e, item, index);
                          }}
                          onMouseLeave={(e: any) => {
                            e.stopPropagation();
                            setAcviteItem(-1);
                          }}
                        >
                          <Space>
                            <Image
                              src={item.cover}
                              style={{
                                borderRadius: 3,
                                width: 40,
                                height: 40,
                              }}
                              fit="cover"
                            />

                            <div>
                              <div>{item.name}</div>
                              <div style={{ color: "#999" }}>{item.artist}</div>
                            </div>
                          </Space>

                          <Space>
                            <>
                              <DeleteOutline
                                style={{
                                  fontSize: 16,
                                  visibility:
                                    activeItem === index ? "visible" : "hidden",
                                }}
                                onClick={(e) => {
                                  deleteItem(item, index);
                                }}
                              />
                            </>
                          </Space>
                        </div>
                      </List.Item>
                    );
                  })}
                </List>
                <Footer label="没有啦!" style={{ borderRadius: 8 }}></Footer>
              </div>
            </Tabs.Tab>

            <Tabs.Tab title="播放历史" key="history">
              {userName ? (
                <>播放历史</>
              ) : (
                <>
                  <Button>请登录</Button>
                </>
              )}
            </Tabs.Tab>

            <Tabs.Tab title="已删除" key="delete_list">
              {userName ? (
                <> 已删除</>
              ) : (
                <>
                  <Button>请登录</Button>
                </>
              )}
            </Tabs.Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
}

const styles = {
  container: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100vw",
    height: "100vh",
    margin: 0,
    padding: 0,
    overflow: "hidden",
    background: "white",
  },
};
