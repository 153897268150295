import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

// import { setLocal, getLocal } from './utils/userData';
// import { doPost } from './http/request';

// import packageJson from '../package.json'; // 根据 package.json 文件的路径进行相对导入

// ~~~~~~~~~~~~~~~~ 获取小程序传过来的的open_id~~~
// const searchArgs = decodeURIComponent(window.location.search.substring(1));
// const urlParams = new URLSearchParams(searchArgs);
// const user_id = urlParams.get('open_id') as string || getLocal('user_id') as string || 'test001';
// setLocal('user_id', user_id)

// 发布新版 要清除本地的local data 不然会报错
// const appVersion = packageJson.version;
// const localVersion = localStorage.getItem('app_version');
// if (appVersion !== localVersion) {
//   localStorage.clear();
//   localStorage.setItem('app_version',appVersion)
// }

//@ts-ignore
const root = ReactDOM.createRoot(document.getElementById("root"));

// 打开StrictMode 过度动画回退会有问题 但是在build环境下又是好的 待上微信验证
// 如果生产有问题 可以对react降级解决    "react": "^16.8.6" "react-dom": "^16.8.6",

//  FingerprintJS 是一个用于生成浏览器设备指纹的 JavaScript 库。
//  它通过收集用户设备和浏览器的各种信息（例如浏览器类型、操作系统、屏幕分辨率、字体、插件等），生成一个独特的标识符（设备指纹），帮助识别不同设备，
//  甚至在用户清除 Cookie、使用隐身模式或更换 IP 地址时，依然可以有效区分用户
//  同一设备请勿重复注册
declare global {
  interface Window {
    visitorId: string; // 根据具体类型声明，比如 string 或者 number
  }
}
const fpPromise = FingerprintJS.load();
fpPromise
  .then((fp) => fp.get())
  .then((result) => {
    // 生成浏览器设备唯一指纹 防止重复注册
    const visitorId = result.visitorId;
    // 全局储存
    window.visitorId = visitorId;

    root.render(
      //<React.StrictMode>
      <App />
      //</React.StrictMode>
    );
    
  });
