
import { Navigate } from 'react-router-dom';
import { lazy, Suspense } from 'react';

// layout文件很小 不需要拆分  只有1kb
import Layout from '../layouts/layout_index';

const Home = lazy(() => import('../pages/home'));
const Mine = lazy(() => import('../pages/mine/mine'));
const MusicLib = lazy(() => import('../pages/musiclib/music'));
const RecommendMore = lazy(() => import('../pages/recommend_more/recommend_more'));
const TopMusic = lazy(() => import('../pages/top_music/top_music'));
const ClassicPlaylist = lazy(() => import('../pages/classic_playlist/classic_playlist'));
// 更多歌单
const ClassicPlaylistMore = lazy(() => import('../pages/classic_playlist_more/classic_playlist_more'));
const NewUser = lazy(() => import('../pages/new_user/new_user'));
const UserLogin = lazy(() => import('../pages/user_login/user_login'));
// 路由组件之间有嵌套关系 才需要children子路由 会根据路由的包含关系逐级渲染~~~~
export const RouterConfig: any = [
  {
    path: "/",
    element: <Navigate to="/home" replace />
  },

  {
    path: '/',
    element: <Layout />,

    children: [
      {
        path: '/home',
        element: <Suspense><Home /> </Suspense>,
   
      },
      {
        path: '/musiclib',
        element: <Suspense><MusicLib /> </Suspense>,
      },
 
      {
        path: '/mine',
        element: <Suspense><Mine /> </Suspense>,
      },
      {
        path: '/classic_playlist',
        element: <Suspense><ClassicPlaylist /> </Suspense>,
      },
      {
        path: '/classic_playlist_more',
        element: <Suspense><ClassicPlaylistMore /> </Suspense>,
      },
      {
        path: '/recommend_more',
        element: <Suspense><RecommendMore /> </Suspense>,
      },
      {
        path: '/top_music',
        element: <Suspense><TopMusic /> </Suspense>,
      },
      ,
      {
        path: '/user_login',
        element: <Suspense><UserLogin /> </Suspense>,
      },
      {
        path: '/new_user',
        element: <Suspense><NewUser /> </Suspense>,
      }
    ]
  }


];