import React from 'react';
import { useLocation } from 'react-router-dom';

import {
  useRoutes,
  BrowserRouter
} from 'react-router-dom';



import './transitionX.css';
import './fade.css'
import { RouterConfig } from './config/RouteConfig';
import { CSSTransition, TransitionGroup } from 'react-transition-group';


export default function App() {
  return (
    <BrowserRouter>
      <MyRouter />
    </BrowserRouter>
  );
}

const MyRouter = () => {

  const location = useLocation();
  const classNames = 'fade';

  return (
    <TransitionGroup
      className={'router-wrapper'}
      childFactory={child => React.cloneElement(child, { classNames })}
    >
      <CSSTransition timeout={500} key={location.pathname}>
        {useRoutes(RouterConfig)}
      </CSSTransition>
    </TransitionGroup >
  );
};

