import axios, { AxiosRequestConfig } from "axios";



/*
错误码:
200 正常
4000 token异常 无效的用户信息
4001 token异常  会话已过期
4004 业务异常
*/


const baseUrl =  process.env.NODE_ENV === 'production' ?  window.location.origin +  '/webapi/web/v1' : window.location.origin;
const request = axios.create({
    baseURL:  baseUrl,
    timeout: 100000,
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        withCredentials: false, // default
    }
});


request.interceptors.request.use(function (config: AxiosRequestConfig) {

    // const mobile_token = localStorage.getItem('__mobileToken__') as string;
    // config.headers = { 'mobile_token': mobile_token };
    return config;


}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});


request.interceptors.response.use(function (response) {

    // const { errorCode, errorMsg } = response.data;
    // // 用户会话异常
    // if (errorCode === 4000 || errorCode === 4001) {
    //     localStorage.removeItem('__mobileToken__');


    //     return response.data;
    // }

    return response.data;


}, function (error) {

    return Promise.reject(error);
});

export default request;




